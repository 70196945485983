/* ==========================================================================
   Mixins
   ========================================================================== */

.modal {
    width: auto;
    max-width: 550px;
    margin: $edge-padding auto;
    position: relative;
    background: #fff;
}

.modal-title {
    height: $modal-title-height;
    margin: 0;
    padding: 0 $modal-title-height 0 30px;
    border-bottom: 2px dotted #ccc;
    line-height: $modal-title-height;
    color: $brand-blue;
}

.modal-content {
    padding: 30px;
    font-size: .9em;

    > *:first-child {
        margin-top: 0;
    }

    > *:last-child {
        margin-bottom: 0;
    }
}

.mfp-close-btn-in .mfp-inline-holder .mfp-close {
    width: $modal-title-height;
    height: $modal-title-height;
    border-left: 2px dotted #ccc;
}

.modal-avatar {
    width: 160px;
    height: 160px;
    display: block;
    margin: 0 auto;
    border-radius: 5px;
}
