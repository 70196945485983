/* ==========================================================================
   Variables
   ========================================================================== */

/*** Colors ***/
$gray:                          #666;
$gray-xxlight:                  lighten($gray, 55%);
$gray-xlight:                   lighten($gray, 45%);
$gray-light:                    lighten($gray, 15%);
$gray-dark:                     darken($gray, 10%);
$gray-xdark:                    darken($gray, 25%);
$gray-xxdark:                   darken($gray, 35%);

$error:                         #d22017;
$warning:                       #f9b729;
$note:                          #1e90ff;
$success:                       #25d252;

$brand-green:                   #94bb1d;
$brand-blue:                    #123c5d;
$brand-primary:                 $brand-green;
$division-ed:                   darken(#b2de2d, 5%);
$division-cd:                   #abc2d4;
$division-dh:                   #e36d38;

$social-facebook:               #3b5998;
$social-twitter:                #1da1f2;
$social-linkedin:               #0077b5;
$social-pinterest:              #bd081c;
$social-github:                 #4078c0;
$social-google-plus:            #dd4b39;
$social-google:                 #4285f4;


/*** Grid ***/
$grid-columns:                  12;
$grid-gutter-horizontal:        20px;
$grid-gutter-small-horizontal:  12px;
$grid-gutter-large-horizontal:  50px;
$grid-gutter-vertical:          30px;
$grid-gutter-small-vertical:    20px;
$grid-gutter-large-vertical:    70px;


/*** Scaffolding ***/
$edge-padding:                  10px;
$wrapper-small:                 550px;
$wrapper-medium:                750px;
$wrapper-large:                 1100px;
$wrapper-xlarge:                1440px;
$section-padding-desktop:       80px;
$section-padding-mobile:        $section-padding-desktop * .5;
$spacer:                        1.5em;


/*** Responsive Breakpoints ***/
$breakpoints: (
    small: 400px,
    medium: 800px,
    large: 1100px
);


/*** Typography ***/
$font-family-body:              'open-sans', sans-serif;
$font-family-heading:           'crete-round', sans-serif;
$font-size:                     16px;
$text-color:                    $gray-dark;
$letter-spacing:                .1em;
$line-height:                   2;
$line-height-computed:          $font-size * $line-height;


/*** Misc ***/
$transition:                    .3s;
$header-desktop-height:         110px;
$header-mobile-height:          60px;
$modal-title-height:            60px;
$page-header-small-height:      150px;
