/* ==========================================================================
   Grid System
   ========================================================================== */

.grid {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;

    &.has-gutter,
    &.has-small-gutter,
    &.has-large-gutter {
        &:last-child {
            margin-bottom: 0;
        }
    }

    &.has-small-gutter {
        margin: (-$grid-gutter-small-vertical) 0 $grid-gutter-small-vertical (-$grid-gutter-small-horizontal);
	}

    &.has-gutter {
        margin: (-$grid-gutter-vertical) 0 $grid-gutter-vertical (-$grid-gutter-horizontal);
	}

    &.has-large-gutter {
        margin: (-$grid-gutter-large-vertical) 0 $grid-gutter-large-vertical (-$grid-gutter-large-horizontal);
	}

    &.is-justifycenter {
        justify-content: center;
	}

    &.is-top {
        align-items: flex-start;
	}

    &.is-bottom {
        align-items: flex-end;
	}

    &.is-center {
        align-items: center;
	}
}

.cell {
    min-height: 1px;
    flex: 1;

    .has-small-gutter > & {
        padding: $grid-gutter-small-vertical 0 0 $grid-gutter-small-horizontal;
	}

    .has-gutter > & {
        padding: $grid-gutter-vertical 0 0 $grid-gutter-horizontal;
	}

    .has-large-gutter > & {
        padding: $grid-gutter-large-vertical 0 0 $grid-gutter-large-horizontal;
	}

    &.is-top {
        align-self: flex-start;
	}

    &.is-bottom {
        align-self: flex-end;
	}

    &.is-center {
        align-self: center;
	}

    &.is-pullright {
        margin-left: auto;
	}

    &.is-pullcenter {
        margin-right: auto;
        margin-left: auto;
    }

    &.is-1 { @include cell-size(1); }
    &.is-2 { @include cell-size(2); }
    &.is-3 { @include cell-size(3); }
    &.is-4 { @include cell-size(4); }
    &.is-5 { @include cell-size(5); }
    &.is-6 { @include cell-size(6); }
    &.is-7 { @include cell-size(7); }
    &.is-8 { @include cell-size(8); }
    &.is-9 { @include cell-size(9); }
    &.is-10 { @include cell-size(10); }
    &.is-11 { @include cell-size(11); }
    &.is-12 { @include cell-size(12); }

    @include media('<=medium') {
        max-width: 100% !important;
        flex: 0 0 100% !important;
    }
}
