/* ==========================================================================
   Forms
   ========================================================================== */

.apply-input input:not([type="checkbox"]):not([type="radio"]),
.apply-input select,
.apply-input textarea,
.input {
    display: inline-block;
    padding: .75em .5em;
    background: rgba(#000, .1);
    border: 0;
    border-radius: 3px;
    outline: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    transition: background $transition;

    &:focus {
        background: rgba(#000, .15);
    }

    .grid & {
        width: 100%;
    }
}

.apply-input textarea,
textarea.input {
    height: 200px;
}

.label {
    font-family: $font-family-heading;
    text-transform: uppercase;
    font-size: .9em;
}

.gform_validation_container {
    display: none !important;
    position: absolute !important;
    left: -9000px;
}
