.contact--info {
    margin-top: 2em;
    line-height: 1.5;

    .address,
    .phone {
        display: block;
    }
}

.contact--form {
    ul {
        list-style: none;
    }

    .gform_fields {
        margin: 0;
        padding: 0;
    }

    .gfield_label {
        @extend .label;
    }

    .gfield_required {
        display: inline-block;
        margin-left: .5em;
    }

    .gform_footer {
        margin-top: 1em;
        text-align: center;
    }

    .gform_button {
        @extend .button.is-bg-primary;
        @extend .button.is-text-white;
    }
}
