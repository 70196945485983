body.single .project {
    .project-hotlinks {
        h3 {
            margin-top: 0;
            text-transform: uppercase;
            font-weight: normal;
            font-size: 1em;
        }

        ul {
            margin: 0;
            padding: 0;
            list-style: none;
        }

        li + li {
            margin-top: 5px;
        }

        a {
            display: flex;
            align-items: center;
            padding: .5em .75em;
            line-height: $line-height * .8;
            font-size: .85em;

            .ext {
                margin-left: auto;
                padding: 0 .45em;
                background: rgba(#000, .075);
                background: #fff;
                border-radius: 3px;
                font-size: .7em;
                text-transform: uppercase;
                color: $gray-light;
            }
        }
    }

    &.division-economic-development {
        .project-hotlinks a {
            background: rgba($division-ed, .2);
            color: darken($division-ed, 20%);

            &:hover {
                background: rgba($division-ed, .4);
            }
        }
    }

    &.division-community-development {
        .project-hotlinks a {
            background: rgba($division-cd, .2);
            color: darken($division-cd, 20%);

            &:hover {
                background: rgba($division-cd, .4);
            }
        }
    }

    &.division-downtown-housing {
        .project-hotlinks a {
            background: rgba($division-dh, .2);
            color: darken($division-dh, 20%);

            &:hover {
                background: rgba($division-dh, .4);
            }
        }
    }
}

.project--gallery {
    background: $brand-blue;

    .photo {
        cursor: pointer;

        &:hover {
            opacity: .7;
        }

        img {
            width: 100%;
            height: auto;
            border-radius: 5px;
        }
    }
}

.project-banner-link {
    display: block;
    margin-top: 2em;
    padding: 60px 30px;
    position: relative;
    overflow: hidden;
    background: center bottom / cover;
    border-radius: 5px;
    color: #fff;

    &::before {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        background: #000;
        opacity: .3;
        transition: $transition;
        content: '';
    }

    &:hover {
        color: #fff;
        
        &::before {
            opacity: .5;
        }
    }

    .banner-title {
        margin: 0 0 .5em;
        position: relative;
        font-size: 2em;
        text-shadow: 0 0 3px rgba(#000, .7);
    }

    .banner-desc {
        position: relative;
        font-size: .9em;
        text-shadow: 0 0 3px rgba(#000, .7);
    }

    .banner-action {
        display: block;
        position: relative;
        text-align: right;
        text-transform: uppercase;
        font-size: .9em;
        opacity: .7;

        i {
            margin-left: .2em;
        }
    }
}
