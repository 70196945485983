/* ==========================================================================
   Base styles: opinionated defaults
   ========================================================================== */

html {
    color: #222;
    font-size: 1em;
    line-height: 1.4;
}

/*
 * Remove text-shadow in selection highlight:
 * https://twitter.com/miketaylr/status/12228805301
 *
 * These selection rule sets have to be separate.
 * Customize the background color to match your design.
 */

::selection {
    background: #b3d4fc;
    text-shadow: none;
}

/*
 * A better looking default horizontal rule
 */

hr {
    height: 1px;
    display: block;
    margin: 1em 0;
    padding: 0;
    border: 0;
    border-top: 1px solid #ccc;
}

/*
 * Remove the gap between audio, canvas, iframes,
 * images, videos and the bottom of their containers:
 * https://github.com/h5bp/html5-boilerplate/issues/440
 */

audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: middle;
}

/*
 * Remove default fieldset styles.
 */

fieldset {
    margin: 0;
    padding: 0;
    border: 0;
}

/*
 * Allow only vertical resizing of textareas.
 */

textarea {
    resize: vertical;
}

/*
 * Reset box-sizing to include paddings and borders.
 */

*,
*::before,
*::after {
    box-sizing: border-box;
}

/* ==========================================================================
   Browser Upgrade Prompt
   ========================================================================== */

.browserupgrade {
    margin: .2em 0;
    padding: .2em 0;
    background: #ccc;
    color: #000;
}
