.page-header {
    height: $page-header-small-height;
    background: $gray-xlight;
    text-align: center;

    h1 {
        margin: 0;
        font-size: 2.5em;
        font-weight: normal;
        text-transform: uppercase;
        color: $brand-blue;
    }
}

.page-container {
    img {
        max-width: 100%;
        height: auto;
    }

    table {
        width: 100%;
        margin: 1.5em 0;
        font-size: .9em;
        border-bottom: 2px dotted $gray-xlight;

        th {
            background: $brand-blue;
            text-align: left;
            text-transform: uppercase;
            font-size: .9em;
            color: #fff;
        }

        th,
        td {
            padding: .5rem;
        }

        tr:nth-child(odd) {
            background: rgba(#000, .05);
        }
    }
}

.back-button {
    margin-bottom: 30px;
}