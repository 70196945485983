.news--posts-list {
    .post + .post {
        margin-top: 10%;
    }

    .entry-title {
        margin: 0;

        a {
            color: $brand-blue;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .entry-excerpt {
        margin-top: 1em;
        @extend %trim-margins;
    }

    .entry-footer {
        margin-top: 1em;
        text-align: right;
    }

    .read-more-button {
        @extend .button.is-bg-blue;
    }
}

body.single .post {
    .entry-header {
        height: 300px;
        background: $gray-xxlight;
        text-align: center;
    }
}

.entry-meta {
    display: flex;
    margin-top: 5px;

    > * + * {
        margin-left: 20px;
    }

    i {
        margin-right: 5px;
    }

    .entry-categories {
        &, a {
            color: $brand-blue;
        }

        a:hover {
            text-decoration: underline;
        }
    }

    .entry-date {
        color: $gray-light;
    }

    @include media('<=medium') {
        display: block;

        > * + * {
            margin-left: 0;
        }
    }
}

.post.category-economic-development {
    .entry-title a {
        color: $division-ed;
    }

    .entry-categories {
        &, a {
            color: $division-ed;
        }
    }

    .read-more-button {
        @extend .button.is-bg-dived;
    }
}

.post.category-community-development {
    .entry-title a {
        color: $division-cd;
    }

    .entry-categories {
        &, a {
            color: $division-cd;
        }
    }

    .read-more-button {
        @extend .button.is-bg-divcd;
    }
}

.post.category-downtown-housing {
    .entry-title a {
        color: $division-dh;
    }

    .entry-categories {
        &, a {
            color: $division-dh;
        }
    }

    .read-more-button {
        @extend .button.is-bg-divdh;
    }
}

.news--sidebar {
    position: sticky;
    top: -40px;

    h3:first-child {
        margin-top: 0;
    }

    .post-categories {
        margin: 1em 0 0;
        padding: 0;
        list-style: none;

        li + li {
            margin-top: 5px;
        }

        .current-cat a {
            background: rgba(#000, .05);

            &::after {
                @extend .ion-checkmark-circled;
                @extend .ion-checkmark-circled:before;
                font-size: 1.2em;
            }
        }

        a {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: .25em .5em;
            border-radius: 3px;
            font-size: .9em;
            color: $brand-blue;

            &:hover {
                background: rgba(#000, .05);
            }

            &::after {
                @extend .ion-chevron-right;
                @extend .ion-chevron-right:before;
                font-size: .9em;
            }
        }

        .cat-item-8 a { color: $division-ed; }
        .cat-item-9 a { color: darken($division-cd, 10%); }
        .cat-item-10 a { color: $division-dh; }
    }
}

.pagination {
    margin-top: 2em;

    .nav-links {
        display: flex;
        justify-content: center;
    }

    .page-numbers {
        padding: .2em .75em;
        border-radius: 3px;
        color: $gray-light;
    }

    .page-numbers + .page-numbers:not(.next) {
        margin-left: 5px;
    }

    a.page-numbers {
        background: $gray-xxlight;
        color: $brand-primary;

        &:hover {
            background: $brand-primary;
            color: #fff;
        }
    }

    .prev {
        margin-right: 15px;
    }

    .next {
        margin-left: 15px;
    }
}
