/* ==========================================================================
   Gallery
   ========================================================================== */

.gallery {
    .owl-item {
        z-index: 1; // Fixes near-edge flickering in Chrome
        float: left;
    }
}

.gallery-stage-loader {
    width: 40px;
    height: 40px;
    display: block;
    margin: -20px 0 0 -20px;
    position: absolute;
    top: 50%;
    left: 50%;
    border-width: 2px;
    border-style: solid;
    border-color: #fff #fff transparent transparent;
    border-radius: 50%;
    opacity: .5;
    animation: loader-spin 1s infinite linear;
}

@keyframes loader-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.gallery-stage {
    padding: 35px 0;
    position: relative;
    background: $brand-blue;
    color: #fff;

    .owl-stage {
        line-height: 1.5;
        font-size: .9em;

        a {
            color: #fff;
            text-decoration: none;
        }
    }
}

.gallery-stage-inner {
    position: relative;
}

.gallery-cast {
    padding: 35px 0;

    .owl-dots {
        display: flex;
        justify-content: center;

        div {
            width: 10px;
            height: 10px;
            margin: 15px 5px 0;
            background: $gray-xlight;
            border-radius: 50%;
            transition: all $transition;

            &:first-child:last-child {
                display: none;
            }

            &.active {
                background: $brand-green;
            }
        }
    }

    .owl-item {
        transition: $transition;
    }

    [data-type="image"] & .current {
        opacity: .5;
    }
}

.gallery-cast-inner {
    position: relative;

    img {
        max-width: 100%;
        height: auto;
    }

    .owl-prev,
    .owl-next {
        width: 30px;
        height: auto;
        top: 0;
        bottom: 25px;
    }

    .owl-prev {
        width: 30px;
        left: -40px;
    }

    .owl-next {
        width: 30px;
        right: -40px;
    }
}

.gallery-cast-member-inner {
    cursor: pointer;
}

.gallery[data-type="image"] .gallery-stage .owl-item {
    background: #000;
}

.owl-carousel .owl-item img {
    width: auto;
    margin: 0 auto;
}

.owl-prev,
.owl-next {
    width: 50px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: 0;

    i {
        width: 30px;
        height: 30px;
        display: block;
        background: rgba(#000, .5);
        border-radius: 50%;
        line-height: 30px;
        text-align: center;
        color: #fff;
        opacity: .6;
        transition: $transition;
    }

    &:hover i {
        opacity: 1;
    }
}

.owl-prev {
    left: 0;
    align-items: flex-end;
}

.owl-next {
    right: 0;
    align-items: flex-start;
}
