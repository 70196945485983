.hero {
    height: 400px;
    position: relative;
    background: center / cover;
    color: #fff;

    &::before {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: $brand-blue;
        opacity: .7;
        content: '';
    }

    h1 {
        text-transform: uppercase;

        span {
            display: block;
        }

        .sub-headline {
            font-size: 1em;
            font-family: $font-family-body;
            font-weight: normal;

            &:last-child {
                text-align: right;
            }
        }

        .main-headline {
            font-size: 3em;
            letter-spacing: .1em;
        }
    }

    .button {
        margin-top: 20px;
    }

    @include media('<=medium') {
        h1 .main-headline {
            font-size: 2em;
        }

        h1 .sub-headline {
            font-size: .8em;
        }
    }
}

.home--divisions {
    .division {
        height: 250px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 5%;
        position: relative;
        text-decoration: none;
        text-align: center;
        color: #fff;
        transition: background $transition;

        .icon {
            width: 80px;
            height: 80px;
            display: block;
            margin: 0 auto;
            background-position: center bottom;
            background-repeat: no-repeat;
            background-size: contain;
        }

        h3 {
            margin: 15px 0 0;
            font-size: 1.3em;
            font-weight: normal;
            text-transform: uppercase;
            color: #fff;
            white-space: pre-line;
        }

        .more-label {
            font-size: .85em;
            text-transform: uppercase;
            opacity: .7;
        }

        i {
            margin-left: .25em;
            line-height: 1;
        }
    }

    .economic-development {
        background: $division-ed;

        &:hover {
            background: darken($division-ed, 10%);
        }

        .icon {
            background-image: url('../images/icon--chart.svg');
        }
    }

    .community-development {
        background: $division-cd;

        &:hover {
            background: darken($division-cd, 10%);
        }

        .icon {
            background-image: url('../images/icon--house.svg');
        }
    }

    .downtown-housing {
        background: $division-dh;

        &:hover {
            background: darken($division-dh, 10%);
        }

        .icon {
            background-image: url('../images/icon--building.svg');
        }
    }
}

.home--about {
    .about-links {
        .cell {
            padding: 2em 0;
        }

        .cell + .cell {
            border-left: 2px dotted $gray-xlight;
        }

        h4 {
            font-size: 1.1em;
            color: $brand-green;
        }

        a {
            font-size: .8em;
            color: $brand-blue;

            &:hover {
                text-decoration: underline;
            }

            i {
                margin-left: .25em;
            }
        }
    }

    .about-vision {
        max-width: 500px;
        margin: 30px auto;
        font-size: 1.5em;
        font-family: $font-family-body;
        font-weight: normal;
        color: $gray-light;

        span {
            display: block;
            margin: 5px 0;
        }

        .first-line {
            text-align: left;
        }

        .last-line {
            text-align: right;
        }

        strong {
            position: relative;
            top: -.1em;
            vertical-align: middle;
            font-family: $font-family-heading;
            font-size: 1.5em;
            font-weight: bold;
            letter-spacing: .075em;
            text-transform: uppercase;
            color: $gray-dark;
        }
    }

    @include media('<=medium') {
        .about-links .cell + .cell {
            border-top: 2px dotted $gray-xlight;
            border-left: 0;
        }
    }
}
