// WordPress Generated Classes
// http://codex.wordpress.org/CSS#WordPress_Generated_Classes

// Media alignment
.alignnone {
    max-width: 100%;
    height: auto;
    margin-right: 0;
    margin-left: 0;
}

.aligncenter {
    height: auto;
    display: block;
    margin: ($spacer / 2) auto;
}

.alignleft,
.alignright {
    height: auto;
    margin-bottom: ($spacer / 2);
}

@include media('<=small') {
    // Only float if not on an extra small device
    .alignleft {
        margin-right: ($spacer / 2);
        float: left;
    }

    .alignright {
        margin-left: ($spacer / 2);
        float: right;
    }
}

.screen-reader-text {
    @extend .visuallyhidden;
}
