/* ==========================================================================
   Footer
   ========================================================================== */

.footer {
    padding: 40px 0;
    position: relative;
    background: $gray-xlight;
    font-size: .8em;

    .sep {
        color: #999;
    }
}

.footer-links {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: flex-end;
    list-style: none;

    li {
        line-height: 1;

        & + li {
            margin-left: 10px;
            padding-left: 10px;
            border-left: 1px solid #999;
        }
    }

    a {
        color: $brand-blue;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}

.top-link {
    width: 50px;
    height: 25px;
    display: block;
    margin-left: -25px;
    position: absolute;
    top: -25px;
    left: 50%;
    background: $gray-xlight;
    border-radius: 25px 25px 0 0;
    box-shadow: inset 0 5px 5px rgba(#000, .2);
    line-height: 35px;
    text-align: center;
    color: #999;
    cursor: pointer;
    transition: $transition;

    &:hover {
        color: $brand-blue;
    }
}

@include media('<=medium') {
    .footer {
        text-align: center;
    }

    .footer-links {
        justify-content: center;
    }

    .top-link {
        display: none;
    }
}
