.ar--links {
    .ar-link {
        display: block;
        position: relative;
        padding: .5em 46px .5em 1em;
        background: $gray-xxlight;
        border-radius: 4px;

        &:hover {
            background: $gray-xlight;
        }

        &::after {
            width: 46px;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 0;
            right: 0;
            font-size: 1.5em;
            @extend .ion-arrow-down-c;
            @extend .ion-arrow-down-c:before;
        }
    }

    .ar-link + .ar-link {
        margin-top: 5px;
    }
}