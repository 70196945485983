/* ==========================================================================
   Saffolding
   ========================================================================== */

.wrapper {
    height: 100%;
    margin-right: auto;
    margin-left: auto;
    position: relative;
    border-right: $edge-padding solid transparent;
    border-left: $edge-padding solid transparent;
    @extend %trim-margins;

    &.is-small { @include wrapper-width($wrapper-small); }
    &.is-medium { @include wrapper-width($wrapper-medium); }
    &.is-large { @include wrapper-width($wrapper-large); }
    &.is-xlarge { @include wrapper-width($wrapper-xlarge); }

    &.is-edgeless {
        border-right: 0;
        border-left: 0;
    }

    // Vertically align child elements
    &.is-center {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    // Remove auto-height & edge padding from child wrappers
    .wrapper {
        height: auto;
        border-right: 0;
        border-left: 0;
    }
}

// Remove leading & trailing margins from direct children
%trim-margins {
    > *:not(.grid):first-child { margin-top: 0; }
    > *:last-child { margin-bottom: 0; }
}

%cover {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.section {
    padding-top: $section-padding-desktop;
    padding-bottom: $section-padding-desktop;
    @extend %trim-margins;

    @include media('<=medium') {
        padding-top: $section-padding-mobile;
        padding-bottom: $section-padding-mobile;
    }
}

.padding-vertical {
    padding-top: $section-padding-desktop;
    padding-bottom: $section-padding-desktop;

    @include media('<=medium') {
        padding-top: $section-padding-mobile;
        padding-bottom: $section-padding-mobile;
    }
}

.padding-horizontal {
    padding-right: $section-padding-desktop;
    padding-left: $section-padding-desktop;

    @include media('<=medium') {
        padding-right: $section-padding-mobile;
        padding-left: $section-padding-mobile;
    }
}

.padding-top {
    padding-top: $section-padding-desktop;

    @include media('<=medium') {
        padding-top: $section-padding-mobile;
    }
}

.padding-right {
    padding-right: $section-padding-desktop;

    @include media('<=medium') {
        padding-right: $section-padding-mobile;
    }
}

.padding-bottom {
    padding-bottom: $section-padding-desktop;

    @include media('<=medium') {
        padding-bottom: $section-padding-mobile;
    }
}

.padding-left {
    padding-left: $section-padding-desktop;

    @include media('<=medium') {
        padding-left: $section-padding-mobile;
    }
}
