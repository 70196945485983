.division-header {
    height: 350px;
    position: relative;
    background: $gray-xxlight center / cover;
    text-align: center;
    color: #fff;

    // Overlay
    &::before {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: .85;
        content: '';
    }

    h1 {
        margin: 0;
        font-size: 2.5em;
        font-weight: normal;
        text-transform: uppercase;
    }

    .icon {
        width: 260px;
        height: 260px;
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        background: center / contain no-repeat;
        opacity: .2;
        transform: translate(-50%, -50%);
    }

    .header-teaser {
        font-size: .85em;
        margin-top: 20px;
    }

    body.term-economic-development &,
    article.division-economic-development & {
        &::before {
            background-color: $division-ed;
        }

        .icon {
            background-image: url('../images/icon--chart.svg');
        }
    }

    body.term-community-development &,
    article.division-community-development & {
        &::before {
            background-color: $division-cd;
        }

        .icon {
            background-image: url('../images/icon--house.svg');
        }
    }

    body.term-downtown-housing &,
    article.division-downtown-housing & {
        &::before {
            background-color: $division-dh;
        }

        .icon {
            background-image: url('../images/icon--building.svg');
        }
    }
}

.division--projects,
.project--sub-projects {
    background: $gray-xxlight;

    .project,
    .sub-project {
        height: 100%;
        padding-bottom: 100px;
        position: relative;
        background: #fff;
        border-radius: 5px;
        text-align: center;
    }

    .project-header {
        height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        background: center / cover;
        border-radius: 5px 5px 0 0;
        overflow: hidden;

        // Overlay
        &::before {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            content: '';
        }


        &[style]::before {
            opacity: .8; // Reduce opacity when bg image present
        }
    }

    .project-title {
        width: 100%;
        margin: 0;
        padding: 0 5%;
        text-align: center;
        position: relative;
        color: #fff;
        font-weight: normal;
        font-size: 36px;
    }

    .project-excerpt {
        padding: 5% 5% 0;
        font-size: .9em;
        @extend %trim-margins;

        &:last-child {
            margin-bottom: 5%;
        }
    }

    .project-footer {
        width: 100%;
        position: absolute;
        bottom: 5%;
        left: 0;
    }

    .project.division-economic-development,
    .sub-project.division-economic-development {
        .project-header::before {
            background: $division-ed;
        }

        .read-more-button {
            @extend .button.is-bg-dived;
        }
    }

    .project.division-community-development,
    .sub-project.division-community-development {
        .project-header::before {
            background: $division-cd;
        }

        .read-more-button {
            @extend .button.is-bg-divcd;
        }
    }

    .project.division-downtown-housing,
    .sub-project.division-downtown-housing {
        .project-header::before {
            background: $division-dh;
        }

        .read-more-button {
            @extend .button.is-bg-divdh;
        }
    }
}


