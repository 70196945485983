.our-team--team {
    background: $brand-blue;

    .person {
        display: block;
        text-align: center;
        cursor: pointer;

        &:hover {
            .person-photo {
                transform: translate(0, -10px);
            }
        }
    }

    .person-photo {
        max-width: 100%;
        height: auto;
        border-radius: 5px;
        transition: $transition;
    }

    .person-name {
        margin: 10px 0 0;
        font-family: $font-family-body;
        font-weight: normal;
        font-size: .9em;
        color: #fff;
        text-transform: uppercase;
    }

    .person-title {
        margin-top: 5px;
        line-height: 1.3;
        font-size: .75em;
        color: rgba(#fff, .6);
    }
}

.our-team--bots {
    background: lighten($gray-xlight, 5%);

    .cell {
        display: flex;
    }

    .person {
        width: 100%;
        min-height: 120px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 15px 20px;
        background: #fff;
        border-radius: 5px;
        text-align: center;
    }

    .person-name {
        max-width: 100%;
        margin: 0;
        font-family: $font-family-body;
        font-weight: normal;
        font-size: .8em;
        text-transform: uppercase;
    }

    .person-info {
        max-width: 100%;
        margin-top: 5px;
        font-size: .7em;
        line-height: 1.5;
        color: $gray-light;
    }
}

.bio-contact-info {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1em 0;

    span {
        display: flex;
        align-items: center;
        font-size: .9em;
        color: $gray;

        &::before {
            margin-right: 5px;
            font-size: 1.5em;
            opacity: .5;
        }

        a {
            color: inherit;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    span + span {
        margin-left: 1em;
    }
}
