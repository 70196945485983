/* ==========================================================================
   Typography
   ========================================================================== */

body {
    line-height: $line-height;
    font-family: $font-family-body;
    font-size: $font-size;
    color: $text-color;
}

h1, h2, h3, h4, h5, h6 {
    margin-top: .75em;
    margin-bottom: .75em;
    line-height: $line-height * .5;
    font-family: $font-family-heading;
}

.sec-title {
    margin: 0 0 .5em;
    font-size: 2.5em;
    text-transform: uppercase;
    color: $brand-blue;
}

%intense {
    text-transform: uppercase;
    letter-spacing: $letter-spacing;
}

.color-primary { color: $brand-primary; }
.color-green { color: $brand-green; }
.color-blue { color: $brand-blue; }
.color-dived { color: $division-ed; }
.color-divcd { color: $division-cd; }
.color-divdh { color: $division-dh; }
.color-white { color: #fff; }
.color-grayxxlight { color: $gray-xxlight; }
.color-grayxlight { color: $gray-xlight; }
.color-graylight { color: $gray-light; }
.color-gray { color: $gray; }
.color-graydark { color: $gray-dark; }
.color-grayxdark { color: $gray-xdark; }
.color-error { color: $error; }
.color-warning { color: $warning; }
.color-note { color: $note; }
.color-success { color: $success; }

.text-left { text-align: left; }
.text-center { text-align: center; }
.text-right { text-align: right; }

a {
    color: $brand-primary;
    text-decoration: none;
    transition: all $transition;

    &:hover {
        color: darken($brand-primary, 10%);
    }

    &:visited {
        color: auto;
    }
}
