.resources--link-groups {
    background: $gray-xxlight;

    .link-group {
        height: 100%;
        background: #fff;
        padding: 1em;
        border-radius: 5px;

        h3 {
            margin-top: 0;
            padding-bottom: .35em;
            border-bottom: 2px dotted $gray-xlight;
        }

        ul {
            margin: 0;
            padding: 0;
            list-style: none;
        }

        a {
            display: flex;
            padding: .25em .5em;
            justify-content: space-between;
            align-items: center;
            position: relative;
            border-radius: 3px;
            font-size: .9em;

            &:hover {
                background: rgba(#000, .05);
            }

            &::after {
                @extend .ion-chevron-right;
                @extend .ion-chevron-right:before;
                font-size: .7em;
            }
        }
    }
}