/* ==========================================================================
   Alerts
   ========================================================================== */

.alert {
    margin: 1rem 0;
    padding: 1rem;
    position: relative;
    border-radius: 3px;
    @extend %trim-margins;
    line-height: $line-height * .84;

    // Icon
    &::before,
    &.has-icon:first-child {
        width: 70px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        font-size: 2rem;
    }

    &.has-icon {
        padding-left: 70px;
    }

    &.is-warning {
        background: lighten($warning, 20%);
        color: darken($warning, 15%);
    }

    &.is-success {
        background: lighten($success, 20%);
        color: darken($success, 15%);
    }

    &.is-error {
        background: lighten($error, 20%);
        color: darken($error, 15%);
    }

    &.is-note {
        background: lighten($note, 20%);
        color: darken($note, 15%);
    }
}
