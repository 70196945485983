.header {
    height: $header-desktop-height;
    background: #fff;

    .logo {
        margin: 0;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(0, -50%);

        a {
            width: 249px;
            height: 50px;
            display: block;
            overflow: hidden;
            background: url('../images/logo.svg') center / contain no-repeat;
            background-size: contain;
            text-indent: -999em;
        }
    }
}

.main-nav {
    position: absolute;
    right: 0;
    bottom: 5px;

    ul {
        display: flex;
        margin: 0;
        padding: 0;
        list-style: none;
    }

    a {
        display: block;
        padding: 10px 10px;
        border-radius: 3px;
        line-height: 1;
        font-size: .9em;
        color: $text-color;
        text-decoration: none;
        transition: $transition;

        &:hover {
            color: #fff;
        }
    }

    .ed a:hover,
    .ed.current-menu-item a {
        background: $division-ed;
        color: #fff;
    }

    .cd a:hover,
    .cd.current-menu-item a {
        background: $division-cd;
        color: #fff;
    }

    .dh a:hover,
    .dh.current-menu-item a {
        background: $division-dh;
        color: #fff;
    }

    @include media('<950px') {
        a {
            font-size: .7em;
        }
    }
}

.sub-nav {
    position: absolute;
    top: 10px;
    right: $edge-padding;
    line-height: 24px;

    ul {
        display: flex;
        margin: 0;
        padding: 0;
        list-style: none;
    }

    ul + ul,
    li + li {
        margin-left: 15px;
    }

    a {
        text-decoration: none;
        text-transform: uppercase;
        font-size: .85em;
        color: $gray-light;
        transition: $transition;

        &:hover {
            color: $brand-blue;
        }
    }

    .social {
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: lighten($gray-light, 10%);
        border-radius: 50%;
        color: #fff;

        &:hover {
            background: $brand-blue;
            color: #fff;
        }
    }
}

.toggle-menu-button {
    width: $header-mobile-height * .66;
    height: $header-mobile-height * .66;
    display: none;
    position: absolute;
    top: 50%;
    right: 0;
    background: rgba(#000, .1);
    border-radius: 5px;
    transform: translate(0, -50%);

    &::before,
    &::after {
        width: 46%;
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        border: solid $brand-blue;
        content: '';
        transform: translate(-50%, -50%);
    }

    &::before {
        height: 36%;
        border-width: 2px 0;
    }

    &::after {
        height: 0;
        border-width: 2px 0 0;
    }
}

@include media('<=medium') {
    html, body {
        overflow-x: hidden;
    }

    .header {
        height: $header-mobile-height;
        position: relative;
        z-index: 999;

        .logo a{
            width: 165px;
            height: $header-mobile-height * .55;
        }
    }

    .navigation-wrapper {
        padding: $edge-padding $edge-padding ($edge-padding * 2);
        position: absolute;
        top: 100%;
        right: -$edge-padding;
        left: -$edge-padding;
        z-index: -1;
        background: #fff;
        transition: $transition;
        transform: translate3d(100%, 0, 0);

        &::after {
            width: 100%;
            height: 30px;
            display: block;
            position: absolute;
            top: 100%;
            left: 0;
            background: linear-gradient(rgba(#000, .2), rgba(#000, 0));
            content: '';
        }
    }

    #toggle-menu-input:checked ~ .navigation-wrapper {
        transform: translate3d(0%, 0, 0);
    }

    .main-nav,
    .sub-nav {
        position: relative;
        top: auto;
        right: auto;
        bottom: auto;

        ul {
            flex-wrap: wrap;
            justify-content: center;
        }

        ul + ul,
        li + li {
            margin-left: 0;
        }

        .menu-item {
            width: 100%;

            a {
                display: block;
                text-align: center;
            }
        }

        .menu-social-item {
            margin-top: 15px;
        }

        .menu-social-item + .menu-social-item {
            margin-left: 15px;
        }
    }

    .main-nav {
        a {
            padding: 1em;
            font-size: 1.1em;
            border-radius: 0;
        }

        .ed a {
            color: $division-ed;
        }

        .cd a {
            color: $division-cd;
        }

        .dh a {
            color: $division-dh;
        }
    }

    .sub-nav {
        a {
            padding: .75em;
            font-size: .9em;
        }

        .social {
            width: 36px;
            height: 36px;
        }
    }

    .toggle-menu-button {
        display: block;
    }
}
